import React from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { allTerms } from "./../graphql/queries/systemQueries";
import { deleteTermsMutation } from "./../graphql/mutations/systemMutations";
import Permissions, {
  settingsNoAccessFallbackList,
} from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import useBaseNavigation from "./../hooks/baseNavigation";
import useListSelection from "./../hooks/rowSelection";
import useNotification from "./../hooks/notifications";
import useModal from "./../hooks/modal";
import { TERM_MODEL_NAME } from "./../configs/constants";
import { ConfirmActionModal } from "./../mood-ui/Modals";
import {
  SuccessNotification,
  ErrorNotification,
} from "./../mood-ui/Notifications";
import PageSpinner from "./../mood-ui/PageSpinner";
import { PageContainer, BlockContainer, Row, Col } from "./../mood-ui/Layout";
import { AddButton } from "./../mood-ui/Controls";
import { ColorLabel, Heading } from "./../mood-ui/Typography";
import Search from "./../mood-ui/Search";
import { PageControls, ListItemCheckbox } from "./../mood-ui/Controls";
import { ItemsNotFound } from "./../mood-ui/Typography";
import {
  TableContainer,
  TableView,
  THeading,
  TRow,
  TCol,
  TContent,
  THCol,
  TableSelectedActions,
  Pagination,
} from "./../mood-ui/DataTable";

import TermListActions from "./../components/list-secondary-actions/TermListActions";
import useItemCounter from "../hooks/itemCounter";
import moment from "moment";

const ALL_TERMS = gql(allTerms);
const DELETE_TERMS = gql(deleteTermsMutation);

const TermList = () => {
  const { protectPage, hasAccess } = usePermissions();
  protectPage(Permissions.Query.Roles, {
    noAccessFallbackList: settingsNoAccessFallbackList,
  });
  const {
    selectedRows,
    isRowSelected: rowSelected,
    toggleRow: toggleRowSelection,
    clearSelection,
    isAllRowsSelected,
    toggleAllRows,
  } = useListSelection();
  const { page, limit, orderBy, orderHow, search } = useParams();

  const { modalState, showModal, closeModal } = useModal();
  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();
  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const { data, loading, error, refetch } = useQuery(ALL_TERMS, {
    variables: {
      page,
      limit,
      orderBy,
      orderHow,
      search,
    },
    fetchPolicy: "cache-and-network",
    onError: (e) => {
      console.log("e", e);
    },
  });

  const { itemsTotal } = useItemCounter(data, { searchKey: "terms" });

  const { nextPage, prevPage, createForm, applySearch } = useBaseNavigation(
    TERM_MODEL_NAME,
    {
      urlContext: "settings",
      showingItemsCount: itemsTotal,
    }
  );

  const [deleteTerms] = useMutation(DELETE_TERMS, {
    onCompleted: (result) => {
      displayNotification({
        heading: "Terms Deleted",
        message: "Successfully deleted selected terms",
        secondsToDisplay: 1,
      });
      clearSelection();
      refetch();
    },
    onError: (e) => {
      displayErrorNotification({
        heading: "Error",
        message: "Failed to delete selected terms",
        secondsToDisplay: 2,
      });
    },
  });

  const confirmDeletion = () => {
    showModal({
      heading: "Confirm Deletion",
      message:
        "Are you sure you want to DELETE selected terms? Do not proceed if you are not sure. Please, note that accepted terms will not be deleted because they are already signed",
      mood: "Danger",
      confirmButtonLabel: "Delete",
      onConfirm: () => {
        closeModal();
        handleDeleteTerms();
      },
      onCancel: () => {
        closeModal();
      },
    });
  };

  const handleDeleteTerms = () => {
    deleteTerms({ variables: { ids: selectedRows } });
  };

  if (error) return <div>Error</div>;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Terms of Use"
              description="List of Terms of Use created for existing clients."
            />
          </Col>
        </Row>
        <Row tweaks="pt-5 pb-4 mt-0 px-5">
          <Col width={9}>
            {hasAccess(Permissions.Mutation.CreateRole) && (
              <AddButton
                label="Add Term"
                onClick={createForm}
                loading={loading}
              />
            )}
          </Col>
          <Col width={3}>
            <Search onApplySearch={applySearch} />
          </Col>
        </Row>
        <TableContainer tweaks="px-5 pb-4">
          <TableView
            tweaks={
              !data || (data && data.terms && data.terms.length === 0)
                ? "border-[#ebedf2]"
                : ""
            }
          >
            <THeading
              tweaks={
                !data || (data && data.terms && data.terms.length === 0)
                  ? "opacity-30 border-b"
                  : ""
              }
            >
              <TRow>
                <THCol tweaks="w-4 text-center">
                  <ListItemCheckbox
                    onChange={() =>
                      toggleAllRows(data?.terms?.map((t) => t.id))
                    }
                    isChecked={
                      isAllRowsSelected(data?.terms?.map((t) => t.id))
                        ? true
                        : false
                    }
                  />
                </THCol>
                <THCol tweaks="text-left" name="id" value="ID" />
                <THCol tweaks="text-left" name="client" value="Client" />
                <THCol tweaks="text-left" name="plan" value="Plan" />
                <THCol tweaks="text-left" name="terms_url" value="Terms Url" />
                <THCol tweaks="text-left" name="terms_url" value="Created At" />
                <THCol
                  tweaks="text-left"
                  name="terms_url"
                  value="Accepted At"
                />
              </TRow>
            </THeading>
            {!data && (
              <tbody className="w-full">
                <tr>
                  <td colSpan="100%">
                    <PageSpinner inline />
                  </td>
                </tr>
              </tbody>
            )}
            {data && data.terms && data.terms.length === 0 && (
              <tbody className="w-full">
                <tr>
                  <td colSpan="100%">
                    <ItemsNotFound text="No terms found" tweaks="" />
                  </td>
                </tr>
              </tbody>
            )}
            {data && data.terms && data.terms.length > 0 && (
              <TContent>
                {data.terms.map((t) => (
                  <TRow key={t.id} isSelected={rowSelected(t.id)}>
                    <TCol tweaks="w-4 text-center">
                      <ListItemCheckbox
                        onChange={() => toggleRowSelection(t.id)}
                        isChecked={rowSelected(t.id) ? true : false}
                      />
                    </TCol>
                    <TCol className="w-24s" value={t.id} />
                    <TCol className="w-24s" value={t.agency.account_name} />
                    <TCol className="w-24s" value={t.new_plan} />
                    <TCol className="w-24s">
                      <span
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => window.open(t.terms_url, "_blank")}
                      >
                        {t.terms_url}
                      </span>
                    </TCol>
                    <TCol className="w-24s">
                      {moment(t.created_at).format("YYYY-MM-DD")}
                    </TCol>
                    <TCol className="w-24s">
                      {t.signed_at && (
                        <ColorLabel
                          color="green"
                          text={moment(t.signed_at).format("YYYY-MM-DD")}
                        />
                      )}
                      {!t.signed_at && <ColorLabel text="Not accepted yet" />}
                    </TCol>
                  </TRow>
                ))}
              </TContent>
            )}
          </TableView>
          <PageControls spaced>
            <Row tweaks="w-full pl-5">
              <Col width={4}>
                <TableSelectedActions>
                  {selectedRows && selectedRows.length > 0 && (
                    <TermListActions
                      onClearSelection={clearSelection}
                      onDelete={confirmDeletion}
                    />
                  )}
                </TableSelectedActions>
              </Col>
              <Col width={5} extraStyles=""></Col>
              <Col width={3}>
                <Pagination onPrev={prevPage} onNext={nextPage} />
              </Col>
            </Row>
          </PageControls>
        </TableContainer>
      </BlockContainer>
      <ConfirmActionModal {...modalState} />
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default TermList;
