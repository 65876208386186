import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { allTags } from "./../graphql/queries/tagQueries";
import {
  allSurveys,
  allSurveyTypes,
} from "./../graphql/queries/vabooSurveyQueries";
import { allMerchants, allOffers } from "./../graphql/queries/benefitQueries";
import {
  allClientsForDataProvider,
  clientTypes,
  campaignTypes,
} from "./../graphql/queries/landlordQueries";
import { allLabels } from "./../graphql/queries/otherQueries";
import {
  DEFAULT_LIST_ROUTE_PARAMS_W_ZERO_LIMIT,
  DEFAULT_LIST_ROUTE_PARAMS_W_ZERO_LIMIT_ID_ORDER,
  DEFAULT_LIST_ROUTE_PARAMS_W_ZERO_LIMIT_EMAIL_ORDER,
  REGULAR_TAG_TYPE_ID,
  EXCLUSION_TAG_TYPE_ID,
} from "./../configs/constants";
import { allTenants } from "../graphql/queries/tenantQueries";
import { allQuestions } from "../graphql/queries/vabooSurveyQueries";
import { allPlans } from "../graphql/queries/systemQueries";
/*
 * Available providers. Default (_keyUnkown) provider loads some dummy data
 * PROVIDERS obj consists from key (providerName) => obj (providerDescription)
 * query - GraphQL query
 * variables - query params
 * fetchMoreHandler - fetchMore function returned by useQuery
 * lookup - here must be implemented logic that takes params and turns it into fetchMore query run
 */
const PROVIDERS = {
  labels: {
    query: gql(allLabels),
    vars: {
      ...DEFAULT_LIST_ROUTE_PARAMS_W_ZERO_LIMIT,
    },
  },
  tags: {
    query: gql(allTags),
    vars: {
      ...DEFAULT_LIST_ROUTE_PARAMS_W_ZERO_LIMIT,
    },
  },
  exclusionTags: {
    query: gql(allTags),
    vars: {
      ...DEFAULT_LIST_ROUTE_PARAMS_W_ZERO_LIMIT,
    },
  },
  offers: {
    query: gql(allOffers),
    vars: {
      ...DEFAULT_LIST_ROUTE_PARAMS_W_ZERO_LIMIT_ID_ORDER,
    },
  },
  surveys: {
    query: gql(allSurveys),
    vars: {
      ...DEFAULT_LIST_ROUTE_PARAMS_W_ZERO_LIMIT_ID_ORDER,
    },
  },
  surveyTypes: {
    query: gql(allSurveyTypes),
    vars: {
      ...DEFAULT_LIST_ROUTE_PARAMS_W_ZERO_LIMIT_ID_ORDER,
    },
  },
  clientTypes: {
    query: gql(clientTypes),
    vars: {
      ...DEFAULT_LIST_ROUTE_PARAMS_W_ZERO_LIMIT_ID_ORDER,
    },
  },
  campaignTypes: {
    query: gql(campaignTypes),
    vars: {
      ...DEFAULT_LIST_ROUTE_PARAMS_W_ZERO_LIMIT_ID_ORDER,
    },
  },
  landlords: {
    query: gql(allClientsForDataProvider),
    vars: {
      ...DEFAULT_LIST_ROUTE_PARAMS_W_ZERO_LIMIT_EMAIL_ORDER,
    },
    useKey: "landlordsForDataProvider",
  },
  questions: {
    query: gql(allQuestions),
    vars: {
      ...DEFAULT_LIST_ROUTE_PARAMS_W_ZERO_LIMIT_ID_ORDER,
    },
  },
  merchants: {
    query: gql(allMerchants),
    vars: {
      ...DEFAULT_LIST_ROUTE_PARAMS_W_ZERO_LIMIT_ID_ORDER,
    },
  },
  plans: {
    query: gql(allPlans),
    vars: {
      ...DEFAULT_LIST_ROUTE_PARAMS_W_ZERO_LIMIT_ID_ORDER,
    },
    useKey: "agencyPlans",
  },
  // permissions: {
  //   query: gql(listOfPermissions),
  //   vars: {
  //     ...DEFAULT_LIST_ROUTE_PARAMS_W_ZERO_LIMIT,
  //   },
  //   fetchMoreHandler: () => {},
  //   lookup: (params) => {},
  // },
  _keyUnknown: {
    query: gql(allTags),
    vars: {
      ...DEFAULT_LIST_ROUTE_PARAMS_W_ZERO_LIMIT,
    },
  },
};

const mergeQueryVars = (vars, filters) => {
  const queryKeys = Object.keys(filters);
  const queryParts = queryKeys.map((queryKey) =>
    filters[queryKey].map((value) => `${queryKey}=${value}`)
  );
  const queryPartsNext = [];
  for (let qp of queryParts) {
    for (let p of qp) {
      queryPartsNext.push(p);
    }
  }
  const queryString =
    queryPartsNext.length > 0 ? `?${queryPartsNext.join("&")}` : "";
  vars.queryString = queryString;
  return vars;
};

const useDataProvider = (
  providerName,
  options = { lazy: false, filters: {} }
) => {
  const querySettings = PROVIDERS[providerName]
    ? PROVIDERS[providerName]
    : PROVIDERS._keyUnknown;

  const queryVars = mergeQueryVars(querySettings.vars, options.filters);

  const { data, loading, error, fetchMore, refetch } = useQuery(
    querySettings.query,
    {
      variables: queryVars,
      fetchPolicy: "cache-and-network",
    }
  );

  const lookup = (options) => {
    fetchMore({
      query: querySettings.query,
      variables: mergeQueryVars(querySettings.vars, options.filters),
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const fetchResult = fetchMoreResult[providerName];
        const newDataCache = { ...prev };
        newDataCache[providerName] = [...fetchMoreResult[providerName]];
        return newDataCache;
      },
    });
  };

  if (loading || error) return [[], false, () => {}];

  const providerNameKey =
    typeof PROVIDERS[providerName].useKey !== "undefined"
      ? PROVIDERS[providerName].useKey
      : providerName;

  if (data && data[providerNameKey]) {
    return [data[providerNameKey], lookup, refetch];
  }
};

export default useDataProvider;
